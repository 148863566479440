$primaryColor: #222222;
$boldfontColor: #747474;
$thinfontColor: #A1A1A1;
$quaternaryColor: red;
$pentiaryColor: green;
$tertiaryColor: blue;
$secondaryColor: blue;

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Ubuntu', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;
