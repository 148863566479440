@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

h2 {
	text-transform: uppercase;
	text-align: center;
	margin-top: 3 * $gutter;
	margin-bottom: $gutter; }


@media screen and (max-width: 994px) {
	.services {
		.pTag {
			padding-top: 10px !important;
			padding-bottom: 10px !important; }
		.servicesHeader {
			margin-top: 50px !important;
			margin-bottom: 5px !important; }
		.customHeaderRed.customHeaderPurpleColor {
			margin-bottom: 5px !important; } }
	.topProducts {
		margin-bottom: -100px !important;
		.offerDescription {
			text-align: center !important; }
		.offerLink {
			padding-top: 10px !important; }
		.offer {
			padding-top: 0px !important; }
		.blackOfferTile {
			padding-top: 40px !important; }
		.redOfferTile {
			padding-top: 40px !important; } } }

.bannerSliderContext {
	padding-top: 20px; }
@media screen and (min-width: 1390px) {
	.topProducts {
		top: -115px !important; } }
@media screen and (max-width: 510px) {
	.bannerSlider {
		display: none; }
	.bannerButtons {
		margin-left: 0px;
		max-width: 130px;
		font-size: 1em; }
	.bannerRicoh {
		padding-left: 10px;
		padding-right: 10px;
		h2 {
			font-size: 1.2em; }
		img {
			width: 70px; } }
	.bannerSliderContext {
		padding-top: 20px;
		h3 {
			padding-right: 20px; }
		h1 {
			font-size: 2.3em; } }
	.topProducts {
		top: 0px; }
	.productCatalogue {
		.vertical-align-helper {
			min-height: 80px !important; }
		.paddingMobile {
			padding-top: 30px; } }
	.bigPadding {
		margin-top: 0px !important; }
	.topProducts {
		top: 0px !important;
		margin-bottom: 20px !important; } }

.bigPadding {
	margin-top: -100px; }
.productImage.offer {
	width: 40px; }

@media screen and (max-width: 1250px) {
	.topProducts {
		.offer {
			padding: 5px !important; }
		.offerDescription {
			text-align: center !important; } } }

.topProducts {
	position: relative !important;
	top: -95px;
	padding-top: 0px;
	padding-bottom: 0px;
	min-height: 200px;
	width: 100%;

	.colorOffers {
		padding-left: 0px !important;
		padding-right: 0px !important;
		h4 {
			margin-top: 0px !important;
			margin-bottom: 0px !important; } }
	.offer {
		padding: 15px;
		h4 {
			font-weight: bold;

			text-transform: uppercase;
			text-align: center;
			padding-top: 20px;
			padding-bottom: 10px;
			color: #6d6d6d; } }
	.green {
		color: #43562a; }
	.white {
		color: white; }
	.blackOfferTile {
		padding: 20px;
		background: #231f20;
		min-height: 110px;
		h4 {
			color: white;
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 1em; }
		a {
			i {
				margin-top: 10px;
				color: #43562a; } } }
	.offerLink {
		color: #cf142b;
		display: block;
		text-align: center;
		padding-top: 0px;
		&:hover {
			text-decoration: none !important;
			h4 {
				color: #cf142b !important; } }
		i {
			font-size: 1.4em; } }
	.redOfferTile {
		padding: 20px;
		background: #cf142b;
		min-height: 110px;
		h4 {
			color: white;
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 1em; }
		a {
			i {
				margin-top: 10px;
				color: white; } }
		a {
			&:hover {
				h4 {
					color: white !important; } } } }
	.customHeaderRed {
		margin-bottom: 45px; }
	.productTypes {
		border-left: 10px solid white;
		border-right: 10px solid white;
		border-top: 10px solid white;
		background: #e6e6e6;
		h4 {
			min-height: 80px; }
		p.offerDescription {
			min-height: 130px; } }

	.colorOffers {
		.offerLink {
			padding-top: 0px !important; } } }

.onlineContactForm {
	.textWrapper {
		padding-top: 20px;
		.customTextArea {
			width: 100%; }
		.formText {
			font-size: 14px;
			color: #fefefe;
			a {
				color: white; } }
		.requiredText {
			font-size: 1.2em;
			color: #808080; } } }
.formWrapper {
	margin-top: 20px;
	padding: 20px;
	padding-bottom: 30px;
	background: #222;
	.customInput {
		width: 100%;
		min-height: 40px;
		border: none; }
	h6 {
		color: white; } }
.services {
	padding-top: 45px;
	padding-bottom: 25px;
	min-height: 300px;
	background: #ededed;
	.pTag {
		text-align: center;
		padding-bottom: 30px;
		font-size: 1.2em;
		color: #7e7e7e;
		min-height: 110px; }
	.customHeaderRed {
		margin-bottom: 25px; }
	.servicesHeader {
		text-align: center;
		font-weight: bold;
		font-size: 1.4em;
		text-transform: uppercase;
		color: #6d6d6d;
		margin-top: 20px;
		min-height: 70px;
		margin-bottom: 20px; }
	.servicesButton {
		width: 100px;
		background: #92013d;
		padding: 10px 15px;
		text-transform: uppercase;
		color: white;
		border: 2px solid #92013d;
		display: block;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		&:hover {
			background: white;
			color: #92013d;
			text-decoration: none !important; } } }

.onlineContactForm {
	padding-bottom: 25px; }
.productCatalogue {
	.tile {
		transition: all 0.5s;
		.col-md-7 {
			padding-left: 5px !important;
			padding-right: 0px !important; }
		&:hover {
			border: 1px solid #cf142b;
			.productHeader {
				color: #cf142b; } } }
	.topPosition {
		position: relative;
		top: -50px; }
	padding-top: 0px;
	padding-bottom: 75px;
	.center {
		text-align: center; }
	.customSmallHeaderBlack {
		margin-bottom: 45px; }
	.tileWrapper {
		padding-left: 0px !important;
		padding-right	: 0px !important; }
	.tile {
		margin-left: 5px;
		margin-right: 5px;
		margin-top: 3px;
		margin-bottom: 3px;
		background: transparent;
		width: 270px;
		display: inline-block;
		min-height: 140px;
		border: 1px solid #e6e6e6;
		.row {
			min-height: 140px; } }
	.productHeader {
		font-weight: bold;
		color: #6c6c6c;
		text-transform: uppercase;
		font-size: 1.2em; }
	.vertical-align-helper {
		min-height: 140px; }
	.onlineDopyt {
		background: #74a839;
		.productHeader {
			color: white;
			font-size: 1.4em; } }
	.tileAnchor {
		&:hover {
			text-decoration: none !important;
			color: inherit !important; } } }
.references {
	padding-top: 25px;
	padding-bottom: 25px;
	background-color: white;
	color: #000; }

@media screen and (max-width: 920px) {
	.productDescription {
		text-align: center !important; } }


@media screen and (max-width: 1010px) {
	.topProducts {
		display: none; }
	.bigPadding {
		margin-top: 0px; } }
.topProductHome {
	margin-left: auto;
	margin-right: auto; }
