@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';



.fullscreenLine {
	width: 100%;
	border: 2px solid #EAEAEA; }
.productDetail {
	img {
		width: 100%; }
	.noPadd {
		padding-left: 40px; }
	.iconEvaluation {
		width: 50px;
		height: 50px;
		display: inline-block;
		margin: 0px; }
	.picture {
		width: 300px;
		height: 300px;
		border: 2px solid #EAEAEA;
		float: right; }
	.description {
		margin-top: 10px;
		padding-bottom: 40px;
		padding-left: 20px; }
	.productName {
		background-color: #222222;
		border-top: 6px solid black;
		margin: 0;
		text-align: center;
		padding: 13px;
		color: white;
		font-size: 20px; }
	.manufacturerName {
		background-color: #ebebeb;
		text-align: center;
		padding: 13px;
		font-size: 20px;
		margin: 4px 0; }
	.product-description {
		color: #595959;
		text-align: justify;
		padding: 12px 0 8px 10px; }
	.onlineDopytButtonDetail {
		padding: 20px 25px;
		font-weight: bold;
		float: right;
		border: 2px solid #74A839;
		color: white;
		display: inline-block;
		margin-top: 10px;
		text-align: center;
		background: #74a839;
		text-transform: uppercase;
		&:hover {
			background: white;
			color: #74A839;
			text-decoration: none !important; } }
	.onlineDopytWrapper {
		border: 2px solid #EAEAEA;
		padding: 20px 25px;
		margin-left: 20px;
		h4 {
			color: #74a839;
			margin-bottom: 0px;
			font-size: 1.6em;
			display: inline-block; }
		h3 {
			color: #74a839;
			font-weight: bold;
			margin-top: 5px;
			display: block; } }
	.receivedEvaluation {
		background: #EAEAEA;
		width: 300px;
		min-height: 100px;
		padding: 20px 10px;
		float: right;
		border: 2px solid white;
		border-top: 0px;
		.text {
			font-size: 0.8em;
			text-align: center; } }
	.technicalParameters {
		text-transform: uppercase;
		font-weight: bold;
		color: #747474;
		margin-bottom: 30px; }
	.parameter {
		color: #747474;
		color: black;
		width: 100%;
		border-bottom: 1px solid #747474;
		.value {
			background: black;
			float: right;
			color: white;
			padding-left: 10px;
			padding-right: 10px; } }
	.price-tag {
		border: 2px solid #f7f7f7;
		margin-bottom: 70px;
		.price-tag-left {
			padding: 30px;
			strong, span {
				color: #595959; } }
		.price-tag-gray {
			padding: 18px;
			background-color: #f7f7f7;
			strong, span {
				color: #222222; }
			.cena {
				font-size: 22px;
				font-weight: bolder; }
			.main-price {
				font-size: 22px;
				display: block;
				text-align: right; }
			.without-dph {
				color: #595959; }
			.another-gray-text {
				display: block;
				text-align: right;
				color: #595959; }
			.amount-to-cart {
				padding: 10px;
				border: 1px solid #bababa;
				margin: 15px 0;
				width: 100%; }
			.add-to-cart {
				background-color: #222222;
				color: white;
				border-radius: 0;
				padding: 13px 35px;
				margin-top: 14px; } } } }

@media screen and (max-width: 999px) {
	.productDetail {
		.picture {
			float: none !important;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px; }
		.receivedEvaluation {
			float: none !important;
			width: 100% !important;
			margin-top: 20px; }
		.productName {
			padding-left: 0px; }
		.description {
			padding-left: 0px; }
		.onlineDopytWrapper {
			margin-left: 0px !important; }
		.noPadd {
			padding-left: 20px; } } }
@media screen and (max-width: 500px) {
	.productDetail {
		.onlineDopytWrapper {
			h3 {
				display: block;
				text-align: center !important; }
			h4 {
				display: block;
				text-align: center !important; }
			.onlineDopytButtonDetail {
				display: block;
				margin-left: auto;
				text-align: center;
				margin-right: auto;
				width: 170px;
				text-align: center;
				float: none !important;
				margin-top: 20px; } } } }
.redColorDopytWrapper {
	margin-top: 40px;
	margin-left: 0px !important;
	h3,h4 {
		color: #cf142b !important; }
	.onlineDopytButtonDetail {
		background: #cf142b;
		border-color: #cf142b;
		&:hover {
			color: #cf142b !important; } } }

.noLeftPadding {
	padding-left: 0px !important; }
.historyBackButton {
	padding: 10px 25px;
	font-weight: 700;
	margin-bottom: 10px;
	border: 2px solid #434040;
	color: #fff;
	display: inline-block;
	margin-top: 30px;
	background: #434040;
	text-transform: uppercase;
	border-radius: 0px;
	float: right;
	left: -210px;
	position: relative; }


.tag_title {
	text-align: center;
	width: 80px;
	display: inline-block;
	.tileWrapper {
		display: inline-block;
		text-align:center {} } }

