@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

@media screen and (max-width: 600px) {
	footer {
		.mapContact {
			padding-top: 10px;
			margin-top: 30px !important;
			display: block !important;
			background: rgba(55,51,52,.9);
			margin-left: auto;
			float: none !important;
			margin-right: auto; }
		.mapContactForm {
			margin-top: 30px;
			float: none !important;
			display: block !important;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px; } } }


footer {
	font-family: 'Ubuntu', sans-serif !important;
	min-height: 100px;
	padding-bottom: 30px;
	background: #EDEDED;
	#footer-panel, #footer-panel-responsive {
		background-color: $primaryColor;
		.footer-icons {
			height: 70px;
			width: 90px;
			display: block;
			background-repeat: no-repeat;
			margin: 30px 0; }
		.footer-contact {
			.col-md-2 {
				background-color: #1a1a1a; } }
		a {
			.col-md-2 {
				&:hover {
					box-shadow: 0 0 5px 5px black; } } }
		h3 {
			color: white;
			font-size: 15px;
			margin-bottom: 0;
			margin-top: 42px;
			text-transform: uppercase; }
		span {
			color: white;
			font-size: 13px; } }
	.footerMenu {
		ul {
			li {
				padding: 0px !important;
				a {
					&:hover {
						color: black !important; } } } } }

	.navbar-nav > li {
		float: none !important; }
	.navbar-nav {
		float: none !important; }

	.footerMenu {
		padding-top: 5px;
		min-height: 60px;
		ul {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
			text-align: center !important;
			margin-left: auto !important;
			margin-right: auto;
			display: block;
			list-style-type: none;
			li {
				display: inline-block;
				list-style-type: none;
				width: auto;
				color: $boldfontColor;
				padding: 15px 10px;
				text-transform: uppercase;
				font-weight: bold; } } }



	.copyrightContent {
		padding-top: 25px;
		text-align: center;
		color: $thinfontColor;
		@media screen and (max-width: 500px) {
			font-size: 9px; }
		.bold {
			color: $boldfontColor; }
		.line {
			padding-left: 10px;
			padding-right: 10px; } }
	a {
		color: $thinfontColor;
		&:hover {
			color: $boldfontColor;
			cursor: pointer; } }
	#footer-panel-responsive {
		display: none; } }
@media screen and (max-width: 772px) {
	.facebookIcon, .youtubeIcon {
		float: none !important; }
	.youtubeIcon {
		margin-right: 10px; }
	.iconWrapper {
		padding-top: 30px !important;
		text-align: center; }
	.facebookIcon {
		margin-left: 20px; }
	.upIconWrapper {
		padding-top: 20px !important;
		.upIcon {
			margin-left: auto;
			margin-right: auto; } } }
@media screen and (min-width: 768px) and (max-width: 1400px) {
	#footer-panel {
		display: none; }
	#footer-panel-responsive {
		display: block !important; } }
